
  
  
  import FETCHING from '../../library/fetching'
  import DATAMASTER from '../../library/dataMaster'
  
  export default {
    data() {
      return {
  
        
      
  
    form : {
        id : '',
        tgl: '',
        uraian: '',
        no_bukti: '',
        jml_terima: '',
        pengeluaran : '',
        sisax : '',
        kode_rekening : '',
        nama_rekening : '',
       keterangan : '',
       unit_kerja_id : '',
      },

      unit_kerja : '', 
      unit_kerja_id : '',

      file_path: this.$store.state.url.URL_APP + "uploads/",

  

  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
        filterku : {
            urusan_kode : '',
            tahun : '',
            unit_kerja : '',
            instansi : '',
        },

  
  
       
        list_data : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        cek_load_data : true,
  
  
        mdl_lihat: false,
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_open : false,
        btn_add: false,
  
  
        FETCHING : FETCHING,
        DATAMASTER : DATAMASTER,
      }
    },
    methods: {
  
  
      getView : function(){
          this.$store.commit("shoWLoading");
          fetch(this.$store.state.url.URL_bku + "view", {
                  method: "POST",
                  headers: {
                  "content-type": "application/json",
                  authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                      data_ke: this.page_first,
                      cari_value: this.cari_value,
                      page_limit : this.page_limit,
                      unit_kerja : this.unit_kerja_id,
                      unit_kerja_id : this.form.unit_kerja_id,
                      tipe : this.tipe
                  })
              })
              .then(res => res.json())
              .then(res_data => {
                  this.list_data = res_data.data;
                  console.log(res_data);
                  this.page_last = res_data.jml_data;
                  this.$store.commit("hideLoading");
                  console.log(res_data);
          });
          },
  
  
          addData: function(){

              var formData = new FormData();
              formData.append('pb', JSON.stringify(this.form));
              formData.append('file', this.form.file);

              fetch(this.$store.state.url.URL_rek + 'addData', {
                  method: "POST",
                  headers: {
                      authorization: "kikensbatara " + localStorage.token
                  },
                  body: formData
                  }).then(res_data => {
                  
                  // this.lampiran.direktori_id
                      this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
                  this.getView();
              });
              },
  
  
      editData : function(){
        fetch(this.$store.state.url.CT_PROGRAM + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.URL_rek + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
  
      selectData : function(data){

          this.form.id= data.id;
          this.form.tgl = data.tgl;
          this.form.uraian = data.uraian;
          this.form.no_bukti = data.no_bukti;
          this.form.jml_terima= data.jml_terima;
          this.form.pengeluaran = data.pengeluaran;
          this.form.sisax = data.sisax;
          this.form.kode_rekening = data.kode_rekening;
          this.form.nama_rekening = data.nama_rekening;
          this.form.keterangan = data.keterangan;
          this.form.unit_kerja_id = data.unit_kerja_id;


          this.m_pajak.id_adm = data.id;


          this.getViewPajak();
          },
  
  
      // ====================================== CONTOH eDOC ====================================
      async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        this.onChangexUnitKerja();
      },
      async onChangexUnitKerja(){
        // console.log("yyyyyyyyyyyy");
        var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
        // console.log("xxxxxxxxxxxxx");
        console.log(unitkerjax[0].id);
        this.filterku.unit_kerja = unitkerjax[0].id
        // console.log("xxxxxxxxxxxxx");
        console.log(this.filterku.unit_kerja);
        this.getView();
      },


      // async asyncFetchUrusan(){
      //   await DATAMASTER.postMasterUrusan('');
      //   this.form.urusan_kode = this.$store.state.list_urusan[0].kode;
      //   this.asyncFetchUrusanBidang(this.form.urusan_kode)
      // },
      // async asyncFetchUrusanBidang(val){
      //   await DATAMASTER.postMasterUrusanBidang(val)
      // },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_program_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {DATAMASTER.postMasterProgramAutoComplete(val, this.form.unit_kerja)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

            indexing : function(index){
                var idx = ((this.page_first-1)*this.page_limit)+index
                return idx
            },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {
      var get_profile = JSON.parse(localStorage.profile);
    this.form.unit_kerja_id = get_profile.profile.unit_kerja;
    this.unit_kerja_id = get_profile.profile.unit_kerja;

    this.tipe = get_profile.profile.trista_monev;

    this.getView();
    },
  }
  